import 'liquid-ajax-cart'
import {devices} from '@/utils/devices';

export default {
  name: 'global',
  store() {
    return {
      isMobileMenuVisible: false,
      isDrawerCartVisible: false,
      isSearchVisible: false,
      device: devices[0].name as 'mobile' | 'tablet' | 'desktop',
      viewport: {vw: window.innerWidth, vh: window.innerHeight},
      prefersReducedMotion: false,
      cart: undefined as any,
      hasBodyLock: false,
      bodyLocksCount: 0,
      savedScrollY: window.scrollY,
      init() {
        this.initLiquidAJaxCart()
        // this.initBundleBuilder()
        this.setDevice()
        window.addEventListener('resize', this.setDevice.bind(this), {passive: true})

        this.initPrefersReducedMotion()
      },
      openMobileMenu() {
        this.isMobileMenuVisible = true
        this.bodyScrollLock(true)
      },
      closeMobileMenu() {
        this.isMobileMenuVisible = false
        this.bodyScrollLock(false)
      },
      toggleMobileMenu() {
        this.isMobileMenuVisible = !this.isMobileMenuVisible
        this.bodyScrollLock(this.isMobileMenuVisible)
      },
      initLiquidAJaxCart() {
        document.addEventListener('liquid-ajax-cart:request-end', (event: Event & any) => {
          const {requestState, cart} = event.detail

          if (requestState.requestType === 'add') {
            if (requestState.responseData?.ok) {
              this.isDrawerCartVisible = true
            }
          }

          this.cart = cart
        })
      },
      setDevice() {
        this.viewport.vw = window.innerWidth
        this.viewport.vh = window.innerHeight

        const currentDevice = devices.find(d => d.breakpoint < this.viewport.vw)?.name || devices[0].name
        this.device = currentDevice
      },
      bodyScrollLock(shouldLock) {
        if (shouldLock) {
          if (!this.hasBodyLock) {
            this.savedScrollY = window.scrollY;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${this.savedScrollY}px`;
            document.body.style.width = '100%';
            document.body.style.overflow = 'hidden';
          }
          this.bodyLocksCount += 1;
          this.hasBodyLock = shouldLock;
        } else {
          this.bodyLocksCount -= 1;

          if (this.hasBodyLock && this.bodyLocksCount === 0) {
            document.body.style.removeProperty('position');
            document.body.style.removeProperty('top');
            document.body.style.removeProperty('width');
            document.body.style.removeProperty('overflow');
            window.scrollTo(0, this.savedScrollY);
            this.hasBodyLock = shouldLock;
          }
        }
      },
      initPrefersReducedMotion() {
        const mql = window.matchMedia("(prefers-reduced-motion: reduce)");
        mql.addEventListener("change", this.handlePrefersReducedMotionChange.bind(this), {passive: true});
        this.prefersReducedMotion = mql.matches;
      },
      handlePrefersReducedMotionChange(event: MediaQueryListEvent) {
        this.prefersReducedMotion = event.matches
      },

      async refreshCart() {
        try {
          await new Promise(resolve => setTimeout(resolve, 500));
          await window.liquidAjaxCart.update();
        } catch (error) {
          console.error('Failed to refresh cart:', error);
        }
      },

      initBundleBuilder() {
        window.bbAfterAddToCart = async () => {
          await this.refreshCart();
          this.isDrawerCartVisible = true;
        }

        document.addEventListener('bb:added-to-cart', async () => {
          await this.refreshCart();
          this.isDrawerCartVisible = true;
        });
      }
    }
  }
}
