import '@/cart'

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'

import AlpineGlobals from '../js/alpine/index'

class App {
  modules: any

  constructor() {
    setTimeout(this.init, 0)
  }

  init = () => {
    // Register and initialize AlpineJS
    window.Alpine = Alpine


    AlpineGlobals.register(Alpine);

    Alpine.plugin(focus)

    Alpine.start()
  }

}

window._app = new App()
