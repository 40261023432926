export default {
  register: Alpine => {
    // Alpine stores
    const alpineStores = import.meta.glob('./stores/*', {eager: true, import: 'default'})

    interface AlpineStore {
      name: string;
      store: () => any;
    }

    interface AlpineComponent {
      name: string;
      component: () => any;
    }

    interface AlpineMagic {
      name: string;
      callback: () => any;
    }

    for (const path in alpineStores) {
      if (path) {
        const store = alpineStores[path] as AlpineStore;
        Alpine.store(store.name, store.store())
      }
    }

    // Alpine components
    const alpineComponents = import.meta.glob('./components/**/*.ts', {eager: true, import: 'default'})
    for (const path in alpineComponents) {
      if (path) {
        const component = alpineComponents[path] as AlpineComponent;
        Alpine.data(component.name, component.component)
      }
    }

    // Alpine magic
    const alpineMagic = import.meta.glob('./magic/**/*', {eager: true, import: 'default'})

    for (const path in alpineMagic) {
      if (path) {
        const magic = alpineMagic[path] as AlpineMagic;
        Alpine.magic(magic.name, magic.callback)
      }
    }
  }
}
