import EmblaCarousel, {EmblaCarouselType, EmblaOptionsType} from 'embla-carousel'
import {WheelGesturesPlugin} from 'embla-carousel-wheel-gestures'

interface SlideshowComponent {
  embla: EmblaCarouselType | null;
  init(wrapper: HTMLElement, options: EmblaOptionsType): void;
  addTogglePrevNextBtnsActive(
    emblaApi: EmblaCarouselType,
    prevBtn: HTMLElement,
    nextBtn: HTMLElement
  ): () => void;
  addPrevNextBtnsClickHandlers(
    emblaApi: EmblaCarouselType,
    prevBtn: HTMLElement,
    nextBtn: HTMLElement
  ): () => void;
  addDotBtnsAndClickHandlers(
    emblaApi: EmblaCarouselType,
    dots: HTMLElement
  ): () => void;
}

export default {
  name: 'slideshow',
  component(): SlideshowComponent {
    return {
      embla: null,

      init(wrapper: HTMLElement, options: EmblaOptionsType) {
        if (!wrapper) return

        const emblaNode = wrapper.querySelector<HTMLElement>('.embla__viewport')
        if (!emblaNode) return

        const emblaApi = EmblaCarousel(emblaNode, options, [WheelGesturesPlugin()])

        const prevBtnNode = wrapper.querySelector<HTMLElement>('.embla__button--prev')
        const nextBtnNode = wrapper.querySelector<HTMLElement>('.embla__button--next')

        if (prevBtnNode && nextBtnNode) {
          const removePrevNextBtnsClickHandlers = this.addPrevNextBtnsClickHandlers(
            emblaApi,
            prevBtnNode,
            nextBtnNode
          )
          emblaApi.on('destroy', removePrevNextBtnsClickHandlers)
        }

        const dots = wrapper.querySelector<HTMLElement>('.embla__dots')
        if (dots) {
          const removeDotBtnsAndClickHandlers = this.addDotBtnsAndClickHandlers(
            emblaApi,
            dots
          )
          emblaApi.on('destroy', removeDotBtnsAndClickHandlers)
        }
      },

      addTogglePrevNextBtnsActive(emblaApi, prevBtn, nextBtn) {
        const togglePrevNextBtnsState = () => {
          prevBtn.toggleAttribute('disabled', !emblaApi.canScrollPrev())
          nextBtn.toggleAttribute('disabled', !emblaApi.canScrollNext())
        }

        emblaApi
          .on('select', togglePrevNextBtnsState)
          .on('init', togglePrevNextBtnsState)
          .on('reInit', togglePrevNextBtnsState)

        return () => {
          prevBtn.removeAttribute('disabled')
          nextBtn.removeAttribute('disabled')
        }
      },

      addPrevNextBtnsClickHandlers(emblaApi, prevBtn, nextBtn) {
        const scrollPrev = () => emblaApi.scrollPrev()
        const scrollNext = () => emblaApi.scrollNext()

        prevBtn.addEventListener('click', scrollPrev)
        nextBtn.addEventListener('click', scrollNext)

        const removeTogglePrevNextBtnsActive = this.addTogglePrevNextBtnsActive(
          emblaApi,
          prevBtn,
          nextBtn
        )

        return () => {
          removeTogglePrevNextBtnsActive()
          prevBtn.removeEventListener('click', scrollPrev)
          nextBtn.removeEventListener('click', scrollNext)
        }
      },

      addDotBtnsAndClickHandlers(emblaApi, dots) {
        let dotNodes: HTMLElement[] = []

        const addDotBtnsWithClickHandlers = () => {
          dots.innerHTML = emblaApi
            .scrollSnapList()
            .map(() => '<button class="embla__dot" type="button"></button>')
            .join('')

          dotNodes = Array.from(dots.querySelectorAll<HTMLElement>('.embla__dot'))
          dotNodes.forEach((dotNode, index) => {
            dotNode.addEventListener('click', () => emblaApi.scrollTo(index))
          })
        }

        const toggleDotBtnsActive = () => {
          const previous = emblaApi.previousScrollSnap()
          const selected = emblaApi.selectedScrollSnap()
          dotNodes[previous]?.classList.remove('embla__dot--selected')
          dotNodes[selected]?.classList.add('embla__dot--selected')
        }

        emblaApi
          .on('init', addDotBtnsWithClickHandlers)
          .on('reInit', addDotBtnsWithClickHandlers)
          .on('init', toggleDotBtnsActive)
          .on('reInit', toggleDotBtnsActive)
          .on('select', toggleDotBtnsActive)

        return () => {
          dots.innerHTML = ''
        }
      },
    }
  },
}
