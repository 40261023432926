export const devices = [
  {name: 'desktop', breakpoint: 1440},
  {name: 'tablet', breakpoint: 768},
  {name: 'mobile', breakpoint: 375},
] as const

export const getDevice = (width: number) => {
  let device: string | null = null
  if (width >= devices[0].breakpoint) {
    device = 'desktop'
  } else if (width >= devices[1].breakpoint && width < devices[0].breakpoint) {
    device = 'tablet'
  } else {
    device = 'mobile'
  }
  return device
}

export const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0
