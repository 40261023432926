interface VideoData {
  playing: boolean;
  muted: boolean;
  videoElement: HTMLVideoElement | null;
  observer: IntersectionObserver | null;
  init(this: VideoData, element: HTMLVideoElement): void;
  destroy(this: VideoData): void;
  play(this: VideoData): void;
  pause(this: VideoData): void;
  togglePlay(this: VideoData): void;
  toggleMute(this: VideoData): void;
}

export default {
  name: 'videoPlayer',
  component() {
    return {
      playing: false,
      muted: true,
      videoElement: null,
      observer: null,

      init(this: VideoData, element: HTMLVideoElement, autoplay: boolean) {
        if (!element) {
          console.warn("Video element not found");
          return;
        }

        this.videoElement = element;
        this.muted = element.muted;

        // Configure IntersectionObserver for auto-play on scroll
        this.observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting && autoplay) {
                this.play();
              } else {
                this.pause();
              }
            });
          },
          {threshold: 0.5}
        );

        this.observer.observe(element);
      },

      destroy(this: VideoData) {
        if (this.observer) {
          this.observer.disconnect();
          this.observer = null;
        }
      },

      play(this: VideoData) {
        if (this.videoElement && !this.playing) {
          this.videoElement.play()
            .then(() => {
              this.playing = true;
            })
            .catch((error) => {
              console.warn('Impossible de lire la vidéo:', error.message || error);

              if (error.name === "NotAllowedError") {
                console.warn("La lecture automatique est bloquée par le navigateur.");
              }
            });
        }
      },

      pause(this: VideoData) {
        if (this.videoElement && this.playing) {
          this.videoElement.pause();
          this.playing = false;
        }
      },

      togglePlay(this: VideoData) {
        if (this.playing) {
          this.pause();
        } else {
          this.play();
        }
      },

      toggleMute(this: VideoData) {
        if (this.videoElement) {
          this.muted = !this.muted;
          this.videoElement.muted = this.muted;
        }
      }
    }
  }
}
